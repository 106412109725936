var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "color-picker-container", style: { width: _vm.width } },
    [
      _c("v-card-subtitle", { staticClass: "pa-4 px-0 text-center" }, [
        _vm._v(_vm._s(_vm.label))
      ]),
      _c(
        "v-color-picker",
        _vm._g(
          _vm._b(
            {
              ref: "input",
              staticClass: "elevation-0 color-picker",
              attrs: { value: _vm.value, width: _vm.width },
              on: { input: _vm.onInput }
            },
            "v-color-picker",
            _vm.attrs,
            false
          ),
          _vm.listeners
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }