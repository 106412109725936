<template>
    <div class="color-box" :style="{ backgroundColor: color }"></div>
</template>

<script>
export default {
    name: 'ColorBox',
    props: {
        color: {
            type: String,
            required: true,
        },
    },
}
</script>

<style scoped lang="scss">
.color-box {
    height: 15px;
    width: 15px;
    border: solid .5px var(--v-secondary_font-base);
    border-radius: 2px;
}
</style>