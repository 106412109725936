<template>
    <div class="color-picker-container" :style="{ width }">
        <v-card-subtitle class="pa-4 px-0 text-center">{{ label }}</v-card-subtitle>
        <v-color-picker
            ref="input"
            :value="value"
            :width="width"
            class="elevation-0 color-picker"
            @input="onInput"
            v-on="listeners"
            v-bind="attrs"
        />
    </div>
</template>

<script>
export default {
    name: 'ColorPickerInput',
    inheritAttrs: false,
    props: {
        value : String,
        width : String,

        label: {
            type: String,
            required: true,
        },
    },
    data: vm => ({
        localValue: vm.value,
    }),
    methods: {
        onInput(value) {
            // Retira as mensagens de erro caso o input não formatado tenha algum valor
            
            this.$emit('input', value ? value : null)
        },

        focus() {
            this.$refs.input.focus()
        },
    },
    computed: {
        computedValue() {
            return this.localValue
        },

        /**
         * Extrai todos os listeners deste componente, exceto os que estamos sobrescrevendo
         */
        listeners() {
            const { input, ...listeners } = this.$listeners
            return listeners
        },
        /**
         * Extrai todas as props deste componente, exceto as que estamos sobrescrevendo
         */
        attrs() {
            const { value, ...attrs } = this.$attrs
            return { ...attrs }
        },
    },
    watch: {
        value(value) {
            this.localValue = value
        },
    },
}
</script>

<style scoped lang="scss">
.color-picker-container {
    border: solid 1px var(--v-secondary_font-base);
    border-radius: 8px;
}

.color-picker {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

</style>